import { Component, Input, OnInit } from '@angular/core';
import { Event } from '../../../../core/models/Event';

@Component({
  selector: 'event-card',
  templateUrl: './event-card.component.html',
  styleUrls: [
    './event-card.component.css'
  ]
})
export class EventCardComponent implements OnInit {
  @Input() event: Event;

  public today: Date = new Date();

  constructor() { }

  ngOnInit() {
  }

}
