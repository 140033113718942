<div class="rvt-container-lg">
  <div class="rvt-p-tb-sm">
    <img src="../../../assets/images/IUMusicLive-logo.png" alt="IUMusicLive!">
    <br>Welcome to live and on-demand streaming from the <b><a href="https://music.indiana.edu" target="_blank" rel="noopener noreferrer" class="page-title-link">Indiana University Jacobs School of Music</a>.</b>
  </div>

  <div class="rvt-row">

    <!--@* START JW PLAYER *@-->
    <div [ngClass]="upcomingEvents == null || upcomingEvents.length > 0 ? 'rvt-cols-8-md' : 'rvt-cols-12-md'" class="rvt-cols-12-sm rvt-p-right-xxs-md-up">

      <!--JW Player skeleton loader-->
      <ngx-skeleton-loader *ngIf="!selectedEvent" [theme]="{ 'border-radius': '4px', 'padding-top': '75%', 'margin-bottom': '-12px' }"></ngx-skeleton-loader>

      <!--JW Player Content-->
      <div *ngIf="selectedEvent" class="jw-player-section">
        <jwplayer [selectedEvent]="selectedEvent"></jwplayer>
      </div>
    </div>
    <!--@* END JW PLAYER *@-->

    <!--@*START UPCOMING EVENTS*@-->
    <div class="rvt-cols-4-md rvt-cols-12-sm rvt-p-left-xxs-md-up">
      <div class="upcoming-events-section">
        <h3 class="rvt-m-tb-xs rvt-hide-md-up">Upcoming IUMusicLive Events</h3>

        <!--Event Card skeleton loader-->
        <div *ngIf="!upcomingEvents" style="margin-bottom:-12px">
          <div *ngFor="let i of [].constructor(3)">
            <ngx-skeleton-loader class="rvt-hide-md-down" [theme]="{ 'border-radius': '4px', 'padding-top': '48%'}"></ngx-skeleton-loader>
            <ngx-skeleton-loader class="rvt-hide-md-up" [theme]="{ 'border-radius': '4px', 'padding-top': '10%'}"></ngx-skeleton-loader>
          </div>
        </div>

        <!--Event Card Content-->
        <event-card *ngFor="let upcomingEvent of upcomingEvents" [event]="upcomingEvent" class="upcoming-events-card"></event-card>
      </div>
    </div>
    <!--@*END UPCOMING EVENTS*@-->

  </div>

  <!--@*START FEATURED LIVE AND ON-DEMAND CONTENT*@-->
  <div class="featured-events-section">
    <div class="rvt-hide-md-down">

      <!--Carousel skeleton loader-->
      <div *ngIf="!events">
        <ngx-skeleton-loader [theme]="{ 'border-radius': '4px', 'padding-top': '20%' }"></ngx-skeleton-loader>
      </div>

      <!--Carousel content-->
      <carousel *ngIf="events" [title]="title" [events]="events" (thumbnailClickedEvent)="setSelectedEvent($event)"></carousel>

    </div>
    <div class="rvt-hide-md-up">
      <events-search *ngIf="events" [title]="title" [events]="events" (thumbnailClickedEvent)="setSelectedEvent($event)"></events-search>
    </div>
  </div>
  <!--@*END FEATURED LIVE AND ON-DEMAND CONTENT*@-->

  <div>
    &nbsp;
  </div>
</div>
