import { Inject, Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Event } from '../../models/Event';
import { Observable } from 'rxjs';
import { Environment } from '../../models/Environment';
import { EventSchedule } from '../../models/EventSchedule';
import { Group } from '../../models/Group';

@Injectable({
  providedIn: 'root'
})
export class PrivacyNoticeService {

  public getPrivacyNoticeUrl = 'https://privacygen.iu.edu/api/v1/notice/12246?format=json';
  //Use below url when developing locally
  //public getPrivacyNoticeUrl = '/notice/12246?format=json';

  constructor(
    private httpBypassInterceptor: HttpClient,
    private handler: HttpBackend
  ) {
    this.httpBypassInterceptor = new HttpClient(handler); //to bypass interceptor when retrieving initial token https://stackoverflow.com/questions/46469349/how-to-make-an-angular-module-to-ignore-http-interceptor-added-in-a-core-module
  }

  public getPrivacyNotice(): Observable<string> {
    //TODO: Move Token to Vault/somewhere more secure
    var token = '06349f57543e1dc5787655ab253c677d0e835ff0';

    return this.httpBypassInterceptor.get<string>(this.getPrivacyNoticeUrl, {
      headers: { 'Authorization': 'Token ' + token }
    })
  }
}
