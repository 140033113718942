import { Pipe, PipeTransform } from '@angular/core';
import { Event } from '../../core/models/Event';
import { EventSortType } from '../../core/enums/EventSortType';
import { SortDirection } from '../../core/enums/SortDirection';


@Pipe({ name: 'sortEvents' })
export class SortEventsPipe implements PipeTransform {
  transform(events: Event[], sortType: EventSortType, sortDirection: SortDirection): any {
    switch (true) {
      case (sortType == EventSortType?.StartDate && sortDirection == SortDirection.Ascending):
        return events.sort((a, b) => {
          //Put null dates at end
          if (a.eventStart == null) {
            return 1;
          }
          if (b.eventStart == null) {
            return -1
          }
          return Date.parse(a.eventStart?.toString()) - Date.parse(b.eventStart?.toString());
        });
        break;
      case (sortType == EventSortType?.StartDate && sortDirection == SortDirection.Descending):
        return events.sort((a, b) => {
          //Put null dates at end
          if (a.eventStart == null) {
            return 1;
          }
          if (b.eventStart == null) {
            return -1
          }
          return Date.parse(b.eventStart?.toString()) - Date.parse(a.eventStart?.toString());
        });
        break;
      case (sortType == EventSortType.Title && sortDirection == SortDirection.Ascending):
        return events.sort((a, b) => cleanText(a.thumbnailTitle).localeCompare(cleanText(b.thumbnailTitle)));
        break;
      case (sortType == EventSortType.Title && sortDirection == SortDirection.Descending):
        return events.sort((a, b) => cleanText(b.thumbnailTitle).localeCompare(cleanText(a.thumbnailTitle)));
        break;
      default:
        return events;
        break;
    }
  }
}

function cleanText(str) {
  var cleanedText: string;

  //strip HTML tags
  cleanedText = str.replace(/<\/?[^>]+(>|$)/g, "");

  //strip special characters
  cleanedText = cleanedText.replace(/[^a-zA-Z0-9]/g, "");

  return cleanedText;
}
