import { Component } from '@angular/core';
import { Subscription, switchMap } from 'rxjs';
import { EventsService } from '../../../core/services/events/events.service';
import { Event } from '../../../core/models/Event';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-private-stream',
  templateUrl: './private-stream.component.html',
  styleUrls: ['./private-stream.component.css']
})
export class PrivateStreamComponent {
  private subscriptions: Subscription = new Subscription();
  public privateEvent: Event;
  public eventId: string;

  constructor(private eventsService: EventsService, private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.eventId = params['eventId'];
    });
  }

  ngOnInit() {
    this.subscriptions.add(
      this.eventsService.getEvent(this.eventId).subscribe(privateEvent => {
        this.privateEvent = privateEvent;
      })
    )
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
