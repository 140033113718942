import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { Event } from '../../../core/models/Event';
import { EventSortType } from '../../../core/enums/EventSortType';
import { SortDirection } from '../../../core/enums/SortDirection';
import Utils from '../../../core/utils/utils';
import tippy from 'tippy.js';

@Component({
  selector: 'events-search',
  templateUrl: './events-search.component.html',
  styleUrls: ['./events-search.component.css']
})
export class EventsSearchComponent implements OnInit, OnChanges {
  @Input() events: Event[];
  @Input() title: string;
  @Input() placeholderTitle: string;
  @Output() thumbnailClickedEvent = new EventEmitter<Event>();

  searchText: string = "";
  filterMetadata = { count: 0 };
  sortType = EventSortType.StartDate;
  sortDirection = SortDirection.Descending;
  sortText = "Date (New - Old)"
  numVisibleEvents = 12; //For limiting number of elements displayed
  numTotalEvents;

  constructor() { }

  ngOnInit() {
    tippy('#help-tooltip', {
      content: `<div>
                  Due to copyright restrictions, only certain performances are available on demand. Additional videos can be found in the <a target='_blank' href='https://media.dlib.indiana.edu/catalog?f%5Bavalon_resource_type_ssim%5D%5B%5D=Moving+Image&f%5Bcollection_ssim%5D%5B%5D=Cook+Music+Library%2C+Jacobs+School+of+Music+Performances'>MCO</a>. Some are publicly available, but the performance you are looking for may be unavailable or restricted to students and staff logged in with IU credentials.
                </div>
                <br />
                <div>
                  Not all performances are recorded with video. You can search the <a target='_blank' href='https://media.dlib.indiana.edu/catalog?f%5Bavalon_resource_type_ssim%5D%5B%5D=Sound+Recording&f%5Bcollection_ssim%5D%5B%5D=Cook+Music+Library%2C+Jacobs+School+of+Music+Performances'>MCO</a> for audio recordings as well.
                </div>`,
      allowHTML: true,
      theme: 'light',
      placement: 'bottom',
      interactive: true
    });
  }

  ngOnChanges() {
  }

  resetEvents() {
    this.events.map(x => x.isImageLoaded = false);
  }

  thumbnailClicked(event: Event) {
    Utils.scrollToTop();
    this.thumbnailClickedEvent.emit(event);
  }

  toggleSort(sortType: string, sortDirection: string) {
    if (sortType == 'title' && sortDirection == 'asc') {
      this.sortType = EventSortType.Title;
      this.sortDirection = SortDirection.Ascending;
      this.sortText = 'Title (A - Z)';
    } else if (sortType == 'title' && sortDirection == 'desc') {
      this.sortType = EventSortType.Title;
      this.sortDirection = SortDirection.Descending;
      this.sortText = 'Title (Z - A)';
    } else if (sortType == 'date' && sortDirection == 'asc') {
      this.sortType = EventSortType.StartDate;
      this.sortDirection = SortDirection.Ascending;
      this.sortText = 'Date (Old - New)';
    } else if (sortType == 'date' && sortDirection == 'desc') {
      this.sortType = EventSortType.StartDate;
      this.sortDirection = SortDirection.Descending;
      this.sortText = 'Date (New - Old)';
    }
  }

  loadMoreEvents() {
    this.numVisibleEvents += 12;
  }

  imageLoaded(eventId) {
    this.events.filter(x => x.id == eventId)[0].isImageLoaded = true;
  }


}
