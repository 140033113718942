import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PrivacyNoticeService } from '../../../core/services/privacy-notice/privacy-notice.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  private subscriptions: Subscription = new Subscription();
  public privacyNoticeHtml: string;
  public effectiveDate: string;

  constructor(private privacyNoticeService: PrivacyNoticeService) { }

  ngOnInit() {
    window.scroll(0, 0);
    this.subscriptions.add(
      this.privacyNoticeService.getPrivacyNotice().subscribe(privacyNotice => {
        this.privacyNoticeHtml = privacyNotice['privacy_notice_text'];
        this.effectiveDate = privacyNotice['last_update'];
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
