import { ActivatedRoute, RouterState } from "@angular/router";

export default class Utils {
  static scrollToTop() {

    window.scroll(0, 0);
  }

  static getTitle(state: RouterState, parent: ActivatedRoute): string[] {
    var data: any[] = [];
    if (parent && parent.snapshot.data && parent.snapshot.data['title']) {
      data.push(parent.snapshot.data['title']);
    }
    if (state && parent && parent.firstChild) {
      data.push(...this.getTitle(state, parent.firstChild));
    }
    return data;
  }

  static removeHTMLTags(text: string): string {
    if (text != null) {
      var cleanedText = text.replace(/<[^>]*>/g, '');
      return cleanedText;
    }

    return "";
  }

  //static transformEventSource = event => {
  //  let isStream = "isStream" in event && event.isStream;
  //  if (event.isOnDemand) {
  //    return event.url;
  //  } else {
  //    return "stream.html?stream=" + (isStream ? 1 : 0) + "&url=" + encodeURIComponent(event.url);
  //  }
  //};
}
