import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/pages/home/home.component';
import { SafePipe } from './pipes/safe/safe.pipe';
import { CarouselComponent } from './components/shared/carousel/carousel.component';
import { Environment } from './core/models/Environment';
import { environment } from '../environments/environment';
import { JwplayerComponent } from './components/shared/jwplayer/jwplayer.component';
import { EventCardComponent } from './components/pages/home/event-card/event-card.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { VideoOnDemandComponent } from './components/pages/video-on-demand/video-on-demand.component';
import { ScheduleComponent } from './components/pages/schedule/schedule.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { CommonModule } from '@angular/common';
import { FilterEventsPipe } from './pipes/filter-events/filter-events.pipe';
import { SortEventsPipe } from './pipes/sort-events/sort-events.pipe';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { EventsSearchComponent } from './components/shared/events-search/events-search.component';
import { HelpComponent } from './components/pages/help/help.component';
import { PrivacyComponent } from './components/shared/privacy/privacy.component';
import { InterceptorService } from './core/services/interceptor/interceptor.service';
import { AuthGuardService } from './core/services/auth-guard/auth-guard.service';
import { PrivateStreamComponent } from './components/pages/private-stream/private-stream.component';
import { EmbeddableStreamComponent } from './components/pages/embeddable-stream/embeddable-stream.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CarouselComponent,
    JwplayerComponent,
    EventCardComponent,
    FooterComponent,
    HeaderComponent,
    VideoOnDemandComponent,
    ScheduleComponent,
    ContactComponent,
    SafePipe,
    FilterEventsPipe,
    SortEventsPipe,
    EventsSearchComponent,
    HelpComponent,
    PrivacyComponent,
    PrivateStreamComponent,
    EmbeddableStreamComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    SlickCarouselModule,
    NgxSkeletonLoaderModule,
    CommonModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuardService], data: { title: 'Home' } },
      { path: 'schedule', component: ScheduleComponent, canActivate: [AuthGuardService], data: { title: 'Schedule' } },
      { path: 'on-demand', component: VideoOnDemandComponent, canActivate: [AuthGuardService], data: { title: 'Video On Demand' } },
      { path: 'help', component: HelpComponent, canActivate: [AuthGuardService], data: { title: 'Help' } },
      { path: 'contact', component: ContactComponent, canActivate: [AuthGuardService], data: { title: 'Contact' } },
      { path: 'privacy', component: PrivacyComponent, canActivate: [AuthGuardService], data: { title: 'Privacy' } },

      { path: 'private-stream', component: PrivateStreamComponent, canActivate: [AuthGuardService], data: { title: 'Private Stream' } },
      { path: 'private-stream/help', component: HelpComponent, canActivate: [AuthGuardService], data: { title: 'Private Stream' } },
      { path: 'private-stream/contact', component: ContactComponent, canActivate: [AuthGuardService], data: { title: 'Private Stream' } },
      { path: 'private-stream/privacy', component: PrivacyComponent, canActivate: [AuthGuardService], data: { title: 'Privacy' } },

      { path: 'embeddable-stream', component: EmbeddableStreamComponent, canActivate: [AuthGuardService], data: { title: 'Embeddable Stream' } },
    ], { useHash: true })
  ],
  providers: [
    { provide: Environment, useValue: environment },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {

}
