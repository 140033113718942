import { CookieService } from "../cookie/cookie.service";
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

@Injectable()
export class InterceptorService implements HttpInterceptor {

  constructor(private cookieService: CookieService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    //Retrieve bearer token from cookie
    const apiKey = this.cookieService.get('apikey');
    req = req.clone({
      url: req.url,
      setHeaders: {
        "X-Api-Key": `${apiKey}`
      }
    });

    return next.handle(req);
  }
}
