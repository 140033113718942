import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [
    './header.component.css'
  ]
})
export class HeaderComponent implements OnInit {

  private subscriptions: Subscription = new Subscription();
  public isPrivateStream: boolean;
  public isEmbeddableStream: boolean;
  public homeRouterLink: string;
  public queryParamsId: number;

  constructor(private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.subscriptions.add(
      this.route.queryParams.subscribe(params => {
        this.queryParamsId = params['eventId'];
      })
    );

    this.subscriptions.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (event.url.includes("private-stream")) {
            this.homeRouterLink = "private-stream";
            this.isPrivateStream = true;
          } else if (event.url.includes("embeddable-stream")) {
              this.isEmbeddableStream = true;
            }
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  toggleMenu() {
    document.getElementById("menu-toggle")?.click();
  }
}
