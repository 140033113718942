<!--Standard iframe configuration for ondemand events-->
<div *ngIf="!shouldUseJWPlayerScript" style="position: relative; overflow: hidden; padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */">
  <img *ngIf="selectedEvent.placeHolderURL"
       [src]="selectedEvent.placeHolderURL | safe"
       style="position: absolute; top: 0; left: 0; bottom: 0; right: 0; width: 100%; height: 100%;" />

  <iframe *ngIf="!selectedEvent.placeHolderURL" title="featured" id="event-iframe"
          [src]="selectedEvent.url | safe"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowfullscreen="true"
          frameborder="0"
          style="position: absolute; top: 0; left: 0; bottom: 0; right: 0; width: 100%; height: 100%;">
  </iframe>
</div>

<!--JWPlayer configuration for streams + non-ondemand events-->
<div *ngIf="shouldUseJWPlayerScript">
  <div id="featured-player"></div>
</div>

<div class="rvt-p-lr-xs player-label">
  <div class="rvt-row">
    <div class="rvt-cols-md rvt-cols-sm">
      <div class="main-title">
        <span [innerHtml]="selectedEvent.title"></span>
      </div>
      <div class="sub-title">
        {{selectedEvent.credit}}
      </div>

      <p class="details">
        {{selectedEvent.venue}} <span *ngIf="selectedEvent.eventStart"> | {{selectedEvent.eventStart | date: 'MMMM d, yyyy h:mm a'}} </span>
      </p>

      <p *ngFor="let associatedEvent of selectedEvent.associatedEvents">
        <a (click)="setSelectedEvent(associatedEvent.associatedEventEventId)" rel="noopener noreferrer" [innerHTML]="associatedEvent.associatedEventEventNavigation.title"></a>
      </p>

      <p *ngFor="let externalLink of selectedEvent.externalLinks">
        <a [href]="externalLink.url" target="_blank" rel="noopener noreferrer"> {{externalLink.title}} </a>
      </p>
    </div>

    <div *ngIf="selectedEvent.alternatePerformanceItems.length > 0" class="rvt-cols-4-md rvt-cols-4-sm alternate-performances">
      Performances:
      <p *ngFor="let alternatePerformance of selectedEvent.alternatePerformanceItems">
        <a (click)="setSelectedEvent(alternatePerformance.alternatePerformanceEventId)" rel="noopener noreferrer"> {{alternatePerformance.altPerfEventNavigation.eventStart | date: 'MMMM d, yyyy h:mm a' }}</a>
      </p>
    </div>
  </div>
</div>

<div class="rvt-accordion collapsible-player-label" data-rvt-accordion="player-label-accordion">
  <h3 class="rvt-accordion__summary main-title-container">
    <button class="rvt-accordion__toggle rvt-p-tb-xs" id="player-label-accordion-1-label" data-rvt-accordion-trigger="player-label-accordion-1">
      <span class="rvt-accordion__toggle-text main-title" [innerHtml]="selectedEvent.title"></span>
      <div class="rvt-accordion__toggle-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <g fill="currentColor">
            <path class="rvt-accordion__icon-bar" d="M8,15a1,1,0,0,1-1-1V2A1,1,0,0,1,9,2V14A1,1,0,0,1,8,15Z" />
            <path d="M14,9H2A1,1,0,0,1,2,7H14a1,1,0,0,1,0,2Z" />
          </g>
        </svg>
      </div>
    </button>
  </h3>
  <div class="rvt-accordion__panel rvt-p-tb-xs" id="player-label-accordion-1" aria-labelledby="player-label-accordion-1-label" data-rvt-accordion-panel="player-label-accordion-1">
    <div class="rvt-row rvt-m-all-remove">
      <div class="rvt-cols-md rvt-cols-sm rvt-p-all-remove">
        <div class="sub-title">
          {{selectedEvent.credit}}
        </div>

        <p class="details">
          {{selectedEvent.venue}} <span *ngIf="selectedEvent.eventStart"> | {{selectedEvent.eventStart | date: 'MMMM d, yyyy h:mm a'}} </span>
        </p>

        <p *ngFor="let associatedEvent of selectedEvent.associatedEvents">
          <a (click)="setSelectedEvent(associatedEvent.associatedEventEventId)" rel="noopener noreferrer" [innerHTML]="associatedEvent.associatedEventEventNavigation.title"></a>
        </p>

        <p *ngFor="let externalLink of selectedEvent.externalLinks">
          <a [href]="externalLink.url" target="_blank" rel="noopener noreferrer"> {{externalLink.title}} </a>
        </p>
      </div>

      <div *ngIf="selectedEvent.alternatePerformanceItems.length > 0" class="rvt-cols-4-md rvt-cols-4-sm rvt-p-all-remove alternate-performances">
        Performances:
        <p *ngFor="let alternatePerformance of selectedEvent.alternatePerformanceItems">
          <a (click)="setSelectedEvent(alternatePerformance.alternatePerformanceEventId)" rel="noopener noreferrer"> {{alternatePerformance.altPerfEventNavigation.eventStart | date: 'MMMM d, yyyy h:mm a' }}</a>
        </p>
      </div>
    </div>
  </div>
</div>
