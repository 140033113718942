import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Event } from '../../../core/models/Event';
import { EventsService } from '../../../core/services/events/events.service';
import { Group } from '../../../core/models/Group';
import { XGroupEvent } from '../../../core/models/XGroupEvent';

@Component({
  selector: 'app-video-on-demand',
  templateUrl: './video-on-demand.component.html',
  styleUrls: ['./video-on-demand.component.css']
})
export class VideoOnDemandComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  public groups: Group[];
  public selectedGroupEvents: Event[];
  public selectedEvent: Event;
  public selectedGroupTitle: string;

  constructor(private eventsService: EventsService) { }

  ngOnInit() {
    this.subscriptions.add(
      this.eventsService.getAllGroups().subscribe(groups => {
        //create all seasons group
        var allGroup = new Group();
        var allGroupTitle = "All Seasons";
        allGroup.title = allGroupTitle;
        allGroup.isFeatured = false;
        allGroup.xGroupEvents = [];
        groups.filter(g => g.isFeatured == false).forEach(g => {
          allGroup.xGroupEvents.push(...g.xGroupEvents);
        });

        this.groups = groups.filter(g => g.isFeatured == false);
        this.groups.unshift(allGroup);

        this.selectedGroupTitle = allGroupTitle.replace(" Seasons", "").replace(" Season", "");
        this.selectedGroupEvents = this.groups[0].xGroupEvents.map(e => e.eventNavigation); //set default selection to first array element
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onSeasonChange(title) {
    this.selectedGroupTitle = title.replace(" Seasons", "").replace(" Season", "");
    var selectedSeason = this.groups.filter(g => g.title == title)[0];
    if (selectedSeason) {
      this.selectedGroupEvents = selectedSeason.xGroupEvents.map(e => e.eventNavigation);
    }
  }

  setSelectedEvent($event: Event) {
    this.eventsService.getEvent($event.id).subscribe(e => {
      this.selectedEvent = e;
    });

    gtag('event', 'select_event_vod', {
      video_title: $event.title
    });
  }

  //extractEvents(group: Group) {
  //  return group.xGroupEvents.map(e => e.eventNavigation);
  //}

}
