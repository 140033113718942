<header *ngIf="!isEmbeddableStream" id="header">

  <div id="skipnav">
    <ul>
      <li><a href="#content">Skip to Content</a></li>
      <li><a href="#nav-main">Skip to Main Navigation</a></li>
    </ul>
    <hr>
  </div>

  <div id="branding-bar" class="iub" itemscope="itemscope" itemtype="http://schema.org/CollegeOrUniversity">
    <div class="row pad">
      <img src="//assets.iu.edu/brand/3.2.x/trident-large.png" alt="" />
      <p id="iu-campus">
        <a href="https://www.indiana.edu" title="Indiana University Bloomington">
          <span id="campus-name" class="show-on-desktop" itemprop="name">Indiana University Bloomington</span>
          <span class="show-on-tablet" itemprop="name">Indiana University Bloomington</span>
          <span class="show-on-mobile" itemprop="name">IU Bloomington</span>
        </a>
      </p>
    </div>
  </div>

  <div *ngIf="!isPrivateStream && !isEmbeddableStream" id="offCanvas" class="hide-for-large" role="navigation" aria-label="Mobile">

    <button id="menu-toggle" class="menu-toggle button hide-for-large" data-toggle="iu-menu" style="background-color: white; color:#990000">Menu</button>

    <div id="iu-menu" class="off-canvas position-right off-canvas-items" data-off-canvas="" data-position="right">
      <div class="mobile off-canvas-list" itemscope="itemscope" itemtype="http://schema.org/SiteNavigationElement">
        <ul (click)="toggleMenu()">
          <li><a routerLink="/" itemprop="url"><span itemprop="name">Home</span></a></li>

          <li><a routerLink="/schedule" itemprop="url"><span itemprop="name">Schedule</span></a></li>

          <li><a routerLink="/on-demand" itemprop="url"><span itemprop="name">Video On Demand</span></a></li>

          <li><a routerLink="/help" itemprop="url"><span itemprop="name">Help</span></a></li>

          <li><a routerLink="/contact" itemprop="url"><span itemprop="name">Contact</span></a></li>
        </ul>
      </div>
      <div class="mobile off-canvas-list" itemscope="itemscope" itemtype="http://schema.org/SiteNavigationElement">
        <ul>
          <li><a href="https://music.indiana.edu/" itemprop="url"><span itemprop="name">Jacobs School of Music Main Site</span></a></li>
        </ul>
      </div>
    </div>
  </div>

  <div *ngIf="isPrivateStream" id="offCanvas" class="hide-for-large" role="navigation" aria-label="Mobile">

    <button id="menu-toggle" class="menu-toggle button hide-for-large" data-toggle="iu-menu" style="background-color: white; color:#990000">Menu</button>

    <div id="iu-menu" class="off-canvas position-right off-canvas-items" data-off-canvas="" data-position="right">
      <div class="mobile off-canvas-list" itemscope="itemscope" itemtype="http://schema.org/SiteNavigationElement">
        <ul (click)="toggleMenu()">
          <li><a routerLink="/private-stream" [queryParams]="{eventId: queryParamsId}" itemprop="url"><span itemprop="name">Home</span></a></li>

          <li><a routerLink="/private-stream/help" [queryParams]="{eventId: queryParamsId}" itemprop="url"><span itemprop="name">Help</span></a></li>

          <li><a routerLink="/private-stream/contact" [queryParams]="{eventId: queryParamsId}" itemprop="url"><span itemprop="name">Contact</span></a></li>
        </ul>
      </div>
      <div class="mobile off-canvas-list" itemscope="itemscope" itemtype="http://schema.org/SiteNavigationElement">
        <ul>
          <li><a href="https://music.indiana.edu/" itemprop="url"><span itemprop="name">Jacobs School of Music Main Site</span></a></li>
        </ul>
      </div>
    </div>
  </div>


  <nav *ngIf="!isPrivateStream && !isEmbeddableStream" aria-label="Main" id="nav-main" role="navigation" itemscope="itemscope" itemtype="http://schema.org/SiteNavigationElement" class="main show-for-large dropdown">
    <ul class="row pad menu-container">
      <li class="home first"><a routerLink="/" aria-label="Home">HOME</a></li>
      <li><a routerLink="/schedule" itemprop="url"><span itemprop="name">SCHEDULE</span></a></li>
      <li><a routerLink="/on-demand" itemprop="url"><span itemprop="name">VIDEO ON DEMAND</span></a></li>
      <li><a routerLink="/help" itemprop="url"><span itemprop="name">HELP</span></a></li>
      <li class="last"><a routerLink="/contact" itemprop="url"><span itemprop="name">CONTACT</span></a></li>
    </ul>
  </nav>

  <nav *ngIf="isPrivateStream" aria-label="Main" id="nav-main" role="navigation" itemscope="itemscope" itemtype="http://schema.org/SiteNavigationElement" class="main show-for-large dropdown">
    <ul class="row pad menu-container">
      <li class="home"><a routerLink="/private-stream" [queryParams]="{eventId: queryParamsId}" aria-label="Home">HOME</a></li>
      <li><a routerLink="/private-stream/help" [queryParams]="{eventId: queryParamsId}" itemprop="url"><span itemprop="name">HELP</span></a></li>
      <li><a routerLink="/private-stream/contact" [queryParams]="{eventId: queryParamsId}" itemprop="url"><span itemprop="name">CONTACT</span></a></li>
    </ul>
  </nav>

</header>

