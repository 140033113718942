<div *ngIf="!isEmbeddableStream" aria-label="Jacobs School of Music resources and social media channels" class="section bg-mahogany dark belt" role="complementary">
  <div class="row pad">
    <h2 class="visually-hidden">Jacobs School of Music resources and social media channels</h2>
    <div class="invert border">
      <!--one-half-->
      <ul class="social">
        <li><a aria-label="Twitter" class="icon-twitter" href="https://twitter.com/iumusiclive" target="_blank">Twitter</a></li>
        <li><a aria-label="Facebook" class="icon-facebook" href="https://www.facebook.com/IUMusicLive/" target="_blank">Facebook</a></li>
        <li><a aria-label="Instagram" class="icon-instagram" href="https://www.instagram.com/iumusiclive/" target="_blank">Instagram</a></li>
        <li><a aria-label="YouTube" class="icon-youtube" href="https://www.youtube.com/user/iu" target="_blank">YouTube</a></li>
        <li><a aria-label="LinkedIn" class="icon-linkedin" href="https://www.linkedin.com/company/indiana-university-jacobs-school-of-music" target="_blank">LinkedIn</a></li>
      </ul>
    </div>
  </div>
</div>


<footer *ngIf="!isEmbeddableStream" id="footer" role="contentinfo" itemscope="itemscope" itemtype="http://schema.org/CollegeOrUniversity">
  <div class="row pad">
    <p class="tagline">Fulfilling <span>the</span> Promise</p>
    <p class="signature">
      <a href="https://www.iu.edu" class="signature-link signature-img"><img src="//assets.iu.edu/brand/3.2.x/iu-sig-formal.svg" alt="Indiana University" /></a>
    </p>

    <p class="copyright">
      <span class="line-break">
        <a href="https://accessibility.iu.edu/assistance/" target="_blank" id="accessibility-link" title="Having trouble accessing this web page content? Please visit this page for assistance.">Accessibility</a> | <a routerLink="/privacy" target="_blank" id="privacy-policy-link">Privacy Notice</a>
      </span>
      <span class="hide-on-mobile"> | </span>
      <a href="https://www.iu.edu/copyright/index.html" target="_blank">Copyright</a> &#169; {{currentYear}}  <span class="line-break-small">
        The Trustees of <a href="https://www.iu.edu/" itemprop="url">
          <span itemprop="name">Indiana University</span>
        </a>
      </span>
    </p>
  </div>
</footer>
