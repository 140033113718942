import { AfterViewInit, Component, HostListener, Input, OnChanges, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Event } from '../../../core/models/Event';
import { EventsService } from '../../../core/services/events/events.service';

declare var jwplayer: any;

@Component({
  selector: 'jwplayer',
  templateUrl: './jwplayer.component.html',
  styleUrls: [
    './jwplayer.component.css'
  ]
})
export class JwplayerComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() selectedEvent: Event;

  private subscriptions: Subscription = new Subscription();
  shouldUseJWPlayerScript: boolean;
  isJWPlayerConfigured: boolean = false;
  watchTimeInSeconds: number = 0;
  startWatchTime: Date | null;
  endWatchTime: Date | null;
  logSelectedEvent: Event; //Used to track which event was being watched. Sends this event to google analytics
  isWindowInForeground: boolean = true;

  constructor(private eventsService: EventsService) { }

  ngOnInit() {
    window.onblur = () => {
      this.logWatchTime();
      this.isWindowInForeground = false;
      this.startWatchTime = new Date();
    };
    window.onfocus = () => {
      this.logWatchTime();
      this.isWindowInForeground = true;
      this.startWatchTime = new Date();
    };
  }

  //beforeunload used to send logs when a user closes the tab or navigates to a different url
  @HostListener('window:beforeunload')
  async ngOnDestroy() {
    await this.logWatchTime();
    await this.subscriptions.unsubscribe();
  }

  ngOnChanges() {
    this.logWatchTime(); //log changes before updating logSelectedEvent
    this.logSelectedEvent = this.selectedEvent;

    this.shouldUseJWPlayerScript = !this.selectedEvent.placeHolderURL && (this.selectedEvent.isStream || !this.selectedEvent.isOnDemand);

    if (this.shouldUseJWPlayerScript) {
      this.configureJWPlayer();
    } else {
      //We cannot accurately track watch time from the Avalon Player
      //Current workaround is to track time the selected Avalon Player event is visible on screen, this could lead to overestimated watch times for Avalon Player items
      this.startWatchTime = new Date();
    }
  }

  ngAfterViewInit() {
    this.configureJWPlayer();
  }

  setSelectedEvent(id) {
    this.logWatchTime();
    this.subscriptions.add(
      this.eventsService.getEvent(id).subscribe(event => {
        this.selectedEvent = event;
        this.logSelectedEvent = this.selectedEvent;
      })
    )
  }

  configureJWPlayer() {
    var sources = {};
    if (this.selectedEvent.isStream) {
      sources = [{ 'file': this.selectedEvent.url }];
    } else {
      sources = [
        { "file": this.selectedEvent.url + '_H264-1080.mp4', "label": '1080p', "default": true },
        { "file": this.selectedEvent.url + '_H264-720.mp4', "label": '720p' },
        { "file": this.selectedEvent.url + '_H264-480.mp4', "label": '480p' },
        { "file": this.selectedEvent.url + '_H264-360.mp4', "label": '360p' }
      ];
    }

    jwplayer.key = 'DkyQ0yYzmR+M6jpICjcW0aHK1ZN8PsZAvY4BHiom4J4=';
    jwplayer('featured-player').setup(
      {
        sources: sources,
        width: "100%",
        aspectratio: "16:9",
        autostart: "true",
        repeat: true
      }
    );

    jwplayer('featured-player').on('play', () => {
      //start or resume timer if it hasn't already started
      if (this.startWatchTime == null) {
        this.startWatchTime = new Date();
      }

      if (this.watchTimeInSeconds == null) {
        this.watchTimeInSeconds = 0;
      }
    });

    jwplayer('featured-player').on('pause', () => {
      //stop timer
      if (this.startWatchTime != null && this.shouldUseJWPlayerScript) {
        this.endWatchTime = new Date();
        this.watchTimeInSeconds += (this.endWatchTime.getTime() - this.startWatchTime.getTime()) / 1000;
        this.startWatchTime = null;
      }
    });

    jwplayer('featured-player').on('complete', () => {
      //stop timer
      this.endWatchTime = new Date();
      this.watchTimeInSeconds += (this.endWatchTime.getTime() - this.startWatchTime!!.getTime()) / 1000;
      this.startWatchTime = null;
    });

    this.isJWPlayerConfigured = true;
  }

  logWatchTime() {
    //jwplayer watch time
    if (this.isJWPlayerConfigured && this.shouldUseJWPlayerScript) {
      if (this.endWatchTime == null && this.startWatchTime != null) {
        this.endWatchTime = new Date();
        this.watchTimeInSeconds += (this.endWatchTime.getTime() - this.startWatchTime.getTime()) / 1000;
      }
      //console.log("seconds spent watching JWPlayer", this.watchTimeInSeconds, this.startWatchTime, this.endWatchTime);
      if (this.watchTimeInSeconds > 1) {
        gtag('event', 'watch_time_jwp', {
          video_title: this.logSelectedEvent.title,
          video_id: this.logSelectedEvent.id,
          watch_time_sec: this.watchTimeInSeconds,
          is_window_foreground: this.isWindowInForeground
        })
      }
    } else {
      //avalon player watch time
      if (this.endWatchTime == null && this.startWatchTime != null) {
        this.endWatchTime = new Date();
        this.watchTimeInSeconds += (this.endWatchTime.getTime() - this.startWatchTime.getTime()) / 1000;
      }
      //console.log("seconds spent watching Avalon player", this.watchTimeInSeconds, this.startWatchTime, this.endWatchTime);
      if (this.watchTimeInSeconds > 1) {
        //cap watch time at 3 hours for Avalon Player
        if (this.watchTimeInSeconds > 10800) {
          this.watchTimeInSeconds = 10800;
        }

        gtag('event', 'watch_time_avalon', {
          video_title: this.logSelectedEvent.title,
          video_id: this.logSelectedEvent.id,
          watch_time_sec: this.watchTimeInSeconds,
          is_window_foreground: this.isWindowInForeground
        })
      }
    }

    //reset timer variables
    this.startWatchTime = null;
    this.endWatchTime = null;
    this.watchTimeInSeconds = 0;
  }
}
