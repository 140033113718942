import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, switchMap } from 'rxjs';
import { EventsService } from '../../../core/services/events/events.service';
import { Event } from '../../../core/models/Event';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription = new Subscription();
  public events: Event[];
  public upcomingEvents: Event[];
  public defaultEvent: Event;
  public selectedEvent: Event;
  public title: string;

  constructor(private eventsService: EventsService) { }

  ngOnInit() {
    this.subscriptions.add(
      this.eventsService.getAllGroups().subscribe(groups => {
        var featuredGroup = groups.filter(g => g.isFeatured)[0];
        this.events = featuredGroup.xGroupEvents.map(e => e.eventNavigation).sort((a, b) => {
          if (a.isLive /*|| (a.eventStart > b.eventStart)*/)
            return -1;
          if (b.isLive /*|| (a.eventStart < b.eventStart)*/)
            return 1;
          return 0;
        });
        this.title = "Featured Live and On-Demand Content";
      })
    );

    this.subscriptions.add(
      this.eventsService.getAllEvents().pipe(
        switchMap(events => {
          this.upcomingEvents = this.getUpcomingEvents(events);
          var defaultEventId = events.find(x => x.isMainPlayerDefault == true)?.id;
          return this.eventsService.getEvent(defaultEventId);
        })
      ).subscribe(defaultEvent => {
          this.defaultEvent = defaultEvent;
          this.selectedEvent = this.defaultEvent;
      })
    )
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  setSelectedEvent($event: Event) {
    //Add default event to carousel when a new event is clicked
    if (this.events.indexOf(this.defaultEvent) == -1) {
      this.events.unshift(this.defaultEvent);
    }

    //Remove default event from carousel when the default event is clicked
    if ($event.id == this.defaultEvent.id) {
      this.events.shift();
    }

    this.eventsService.getEvent($event.id).subscribe(e => {
      this.selectedEvent = e;
    })

    //only track carousel click events in Google Analytics if it's not a live event, and not the default event
    if ($event.isLive == false && $event.id != this.defaultEvent.id) {
      gtag('event', 'select_event_vod_carousel', {
        video_title: $event.title
      });
    }

    if ($event.isLive == true) {
      gtag('event', 'select_event_live_carousel', {
        video_title: $event.title
      });
    }
  }

  getUpcomingEvents(events: Event[]): Event[] {
    var numEventsToSelect: number = 3;
    var upcomingEvents: Event[] = events.filter(x => new Date(x.eventStart) > new Date() && x.isUpcomingItem == true).sort((a, b) => {
      //return a.eventStart < b.eventStart ? -1 : 1
      return a.upcomingOrder < b.upcomingOrder ? -1 : 1;
    }).slice(0, numEventsToSelect);
    return upcomingEvents;
  }
}
