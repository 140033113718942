import { Inject, Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Event } from '../../models/Event';
import { Observable } from 'rxjs';
import { Environment } from '../../models/Environment';
import { EventSchedule } from '../../models/EventSchedule';
import { Group } from '../../models/Group';
import { LiveWhaleEventResult } from '../../models/LiveWhaleEventResult';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  public readonly apiBaseUrl: string;
  public readonly liveWhaleApiBaseUrl: string;
  public getAllEventsUrl = '/api/Events';
  public getAllGroupsUrl = '/api/Groups/All'
  public getScheduleUrl = '/live/json/v2/events/paginate/false/group_id/56/tag_id/2752/response_fields/summary,image,location,"'
  public getEventIgnoreQueryFiltersUrl = '/api/Events/{id}/Ignore';

  constructor(
    private http: HttpClient,
    private httpBypassInterceptor: HttpClient,
    private environment: Environment,
    private handler: HttpBackend
  ) {
    this.getAllEventsUrl = this.environment.apiBaseUrl + this.getAllEventsUrl;
    this.getAllGroupsUrl = this.environment.apiBaseUrl + this.getAllGroupsUrl;
    this.getScheduleUrl = this.environment.liveWhaleApiBaseUrl + this.getScheduleUrl;
    this.getEventIgnoreQueryFiltersUrl = this.environment.apiBaseUrl + this.getEventIgnoreQueryFiltersUrl;
    this.httpBypassInterceptor = new HttpClient(handler); //to bypass interceptor when retrieving initial token https://stackoverflow.com/questions/46469349/how-to-make-an-angular-module-to-ignore-http-interceptor-added-in-a-core-module
  }

  public getAllEvents(): Observable<Event[]> {
    return this.http.get<Event[]>(this.getAllEventsUrl)
  }

  public getAllGroups(): Observable<Group[]> {
    return this.http.get<Group[]>(this.getAllGroupsUrl)
  }

  public getSchedule(): Observable<LiveWhaleEventResult[]> {
    //bypass interceptor for non-IUML API calls
    return this.httpBypassInterceptor.get<LiveWhaleEventResult[]>(this.getScheduleUrl);
  }

  public getEvent(id): Observable<Event> {
    return this.http.get<Event>(this.getEventIgnoreQueryFiltersUrl.replace("{id}", id));
  }

  public getEventBypassInterceptor(id): Observable<Event> {
    //bypass interceptor for components that cannot use cookie to store read-only API key (embeddable-stream component)
    const headerDict = {
      "X-Api-Key": this.environment.readOnlyApiKey
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict)
    };

    return this.httpBypassInterceptor.get<Event>(this.getEventIgnoreQueryFiltersUrl.replace("{id}", id), requestOptions);
  }
}
