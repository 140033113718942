<div class="upcoming-events-card">
  <div *ngIf="event.eventStart" class="upcoming-events-card-header">
    {{event.eventStart | date: 'MMM dd, yyyy - h:mm a'}} ET
  </div>
  <div class="upcoming-events-card-body">
    <div class="upcoming-events-card-title">
      <a [href]="event.url" [innerHtml]="event.title" target="_blank"></a>
    </div>
    <div>
      <div class="upcoming-events-card-subtitle">
        <span *ngIf="event.subTitle">{{event.subTitle}}</span>
        <span *ngIf="!event.subTitle">{{event.credit}}</span>
      </div>
      <div class="upcoming-events-card-detail">
        {{event.detail}}
      </div>
    </div>
  </div>
  <!--<div *ngIf="event.isLive || (event.eventStart > today && event.eventEnd < today)" class="upcoming-events-card-footer">
    <button class="upcoming-events-card-livewatchnowlink">Watch Live Now! </button>--><!--@onclick="() => ChangeStream(eventItem.Id)"-->
  <!--</div>-->
</div>


