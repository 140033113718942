import { Injectable } from '@angular/core';
import { Environment } from '../../models/Environment';
import { CookieService } from '../cookie/cookie.service';

@Injectable({
  providedIn: 'root'
})
export class ApiKeyService {

  constructor(
    private environment: Environment,
    private cookieService: CookieService
  ) { }

  public getKey(): string {
    return this.environment.readOnlyApiKey;
  }

  isKeyValid(): boolean {
    //initial check for null token
    var token = this.cookieService.get('api-key');
    if (token == null)
      return false;
    else
      return true;
  }
}
