<div class="rvt-container-lg">
  <h2 class="rvt-p-tb-sm rvt-m-bottom-none">Events Schedule</h2>

  <div class="schedule-section">

    <!--Skeleton laoders-->
    <div *ngIf="!eventSchedules">
      <div *ngFor="let i of [].constructor(4)">
        <ngx-skeleton-loader [theme]="{ 'border-radius': '4px', height: '32px', width:'80px', 'padding': '8px 0' }"></ngx-skeleton-loader> 
        <ngx-skeleton-loader [theme]="{ 'border-radius': '4px', 'padding-top': '10%' }"></ngx-skeleton-loader>
      </div>
    </div>

    <!--Content-->
    <div *ngIf="eventSchedules">
      <div *ngFor="let eventSchedule of eventSchedules; let index = index">
        <p *ngIf="!eventSchedules[index-1] || eventSchedule.date !== eventSchedules[index-1].date" class="event-group-header">{{eventSchedule.date}}</p>

        <div class="rvt-flex rvt-bg-black-100 rvt-m-tb-xs rvt-p-tb-xs">
          <div class="thumbnail rvt-p-lr-xs">
            <img [src]="eventSchedule.thumbnail" [alt]="eventSchedule.thumbnail_caption" (error)="hideImg($event)" />
          </div>
          <div class="event-details rvt-flex rvt-grow-1 rvt-p-right-xs">
            <div class="rvt-grow-1">
              <p class="event-title"> <a [href]="eventSchedule.url" target="_blank" rel="noopener noreferrer"> {{eventSchedule.title}}</a> </p>
              <p>{{eventSchedule.summary}}</p>
            </div>
            <div class="rvt-shrink-0 rvt-p-right-md rvt-text-right event-time-location">
              <p>{{eventSchedule.custom_room_number}}</p>
              <p>{{eventSchedule.date_time}}</p>
              <p *ngIf="eventSchedule.location.length > 0">{{locationStringCleaner(eventSchedule.location)}}</p>
            </div>
          </div>
        </div>
      </div>


      <br />
      <a href="https://events.iu.edu/musiciub/" class="more button" target="_blank">
        View more events
        <svg fill="currentColor" width="16" height="16" viewBox="0 0 16 16" id="rvt-icon-link-external" style="width: 14px; height: 14px;">
          <path d="M15 1H9v2h2.586l-3 3L10 7.414l3-3V7h2V1Z"></path>
          <path d="M7 3H1v12h12V9h-2v4H3V5h4V3Z"></path>
        </svg>
      </a>
    </div>
  </div>
</div>
