import { Component, AfterViewInit, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Event } from '../../../core/models/Event';
import { EventSortType } from '../../../core/enums/EventSortType';
import { SortDirection } from '../../../core/enums/SortDirection';
import Utils from '../../../core/utils/utils';

@Component({
  selector: 'carousel',
  templateUrl: './carousel.component.html',
  styleUrls: [
    './carousel.component.css'
  ]
})
export class CarouselComponent implements OnInit {
  @Input() events: Event[];
  @Input() title: string;
  @Output() thumbnailClickedEvent = new EventEmitter<Event>();

  searchText: string = "";
  filterMetadata = { count: 0 };
  sortType = EventSortType.StartDate;
  sortDirection = SortDirection.Ascending;
  numVisibleEvents = 10; //For limiting number of elements displayed in mobile list view
  numTotalEvents;

  constructor() { }

  ngOnInit() {
  }

  slideConfig = {
    "slidesToShow": 3,
    "slidesToScroll": 2,
    "prevArrow": '<button type="button" class="slick-prev" style=""> Previous </button>',
    "nextArrow": '<button type="button" class="slick-next" style=""> Next </button>'
  };

  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {
    console.log('breakpoint');
  }

  afterChange(e) {
    console.log('afterChange');
  }

  beforeChange(e) {
    console.log('beforeChange');
  }

  thumbnailClicked(event: Event) {
    Utils.scrollToTop();
    this.thumbnailClickedEvent.emit(event);
  }

  toggleSort(sortType: string) {
    switch (sortType) {
      case ('alpha'):
        this.sortType = EventSortType.Title;
        this.sortDirection = this.sortDirection == SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending;
        break;
      case ('chrono'):
        this.sortType = EventSortType.StartDate;
        this.sortDirection = this.sortDirection == SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending;
        break;
      default:
        break;
    }
  }

  loadMoreEvents() {
    this.numVisibleEvents += 10;
  }

  _trackBy(event) {
    return event.img;
  }

}
