import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventSchedule } from '../../../core/models/EventSchedule';
import { EventsService } from '../../../core/services/events/events.service';
import utils from '../../../core/utils/utils';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription = new Subscription();
  eventSchedules: EventSchedule[];

  constructor(private eventsService: EventsService) { }

  ngOnInit() {
    this.subscriptions.add(
      this.eventsService.getSchedule().subscribe(eventScheduleResult => {
        var eventSchedules = eventScheduleResult['data'];

        eventSchedules.map(function (val, index) {
          val.summary = utils.removeHTMLTags(val.summary);
        });
        this.eventSchedules = eventSchedules;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  locationStringCleaner(locationString: string) {
    return locationString.replace(" &amp; IUMusicLive!", "");
  }

  hideImg($event) {
    var target = $event.target;
    target.style.display = 'none';
    target.parentNode.style.width = '10px';
  }

}
