import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CookieService } from '../cookie/cookie.service';
import { ApiKeyService } from '../api-key/api-key.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(private apiKeyService: ApiKeyService, private cookieService: CookieService) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {

    if (!this.apiKeyService.isKeyValid()) {
      var apiKey = this.apiKeyService.getKey();
      this.cookieService.set('apikey', apiKey);
      return true;
    } else {
      return true;
    }
  }
}
