<div class="rvt-container-lg">
  <h2 class="rvt-p-tb-sm rvt-m-bottom-none">Help</h2>
  <div class="help-section">
    <div class="help-section-content">
      If you are encountering problems with the stream, please check out the topics below. Most problems can be solved by refreshing your browser, emptying the cache, restarting your browser, and in some cases a reboot of your system. If you are still encountering problems please send an email to <a href="mailto:iuml@iu.edu">iuml@iu.edu</a>. We check this account regularly during live webcasts. You can also send a message to the chat.

      <div class="rvt-accordion rvt-p-top-md" data-rvt-accordion="test-accordion">

        <h3 class="rvt-accordion__summary rvt-m-bottom-none">
          <button class="rvt-accordion__toggle" id="test-accordion-3-label" data-rvt-accordion-trigger="test-accordion-3">
            <span class="rvt-accordion__toggle-text">The page is not loading correctly or the live stream is not working</span>
            <div class="rvt-accordion__toggle-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <g fill="currentColor">
                  <path class="rvt-accordion__icon-bar" d="M8,15a1,1,0,0,1-1-1V2A1,1,0,0,1,9,2V14A1,1,0,0,1,8,15Z" />
                  <path d="M14,9H2A1,1,0,0,1,2,7H14a1,1,0,0,1,0,2Z" />
                </g>
              </svg>
            </div>
          </button>
        </h3>
        <div class="rvt-accordion__panel" id="test-accordion-3" aria-labelledby="test-accordion-3-label" data-rvt-accordion-panel="test-accordion-3">
          <div>
            Check to make sure you are running the latest version of your browser.
          </div>
          <div>
            Your browser may have loaded a cached version of the website. Try refreshing your browser. If that does not work, empty the cache and then refresh your browser again
          </div>
          <div><a href="https://kb.iu.edu/d/ahic" target="_blank">Clear your web browser's cache, cookies, and history</a></div>
        </div>

        <h3 class="rvt-accordion__summary rvt-m-bottom-none">
          <button class="rvt-accordion__toggle" id="test-accordion-4-label" data-rvt-accordion-trigger="test-accordion-4">
            <span class="rvt-accordion__toggle-text">I am hearing an echo</span>
            <div class="rvt-accordion__toggle-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <g fill="currentColor">
                  <path class="rvt-accordion__icon-bar" d="M8,15a1,1,0,0,1-1-1V2A1,1,0,0,1,9,2V14A1,1,0,0,1,8,15Z" />
                  <path d="M14,9H2A1,1,0,0,1,2,7H14a1,1,0,0,1,0,2Z" />
                </g>
              </svg>
            </div>
          </button>
        </h3>
        <div class="rvt-accordion__panel" id="test-accordion-4" aria-labelledby="test-accordion-4-label" data-rvt-accordion-panel="test-accordion-4">
          You may have two instances of the video player open. Check for additional windows or tabs in your browser.
        </div>

        <h3 class="rvt-accordion__summary rvt-m-bottom-none">
          <button class="rvt-accordion__toggle" id="test-accordion-6-label" data-rvt-accordion-trigger="test-accordion-6">
            <span class="rvt-accordion__toggle-text">The video is jerky, buffers, or stops playing</span>
            <div class="rvt-accordion__toggle-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <g fill="currentColor">
                  <path class="rvt-accordion__icon-bar" d="M8,15a1,1,0,0,1-1-1V2A1,1,0,0,1,9,2V14A1,1,0,0,1,8,15Z" />
                  <path d="M14,9H2A1,1,0,0,1,2,7H14a1,1,0,0,1,0,2Z" />
                </g>
              </svg>
            </div>
          </button>
        </h3>
        <div class="rvt-accordion__panel" id="test-accordion-6" aria-labelledby="test-accordion-6-label" data-rvt-accordion-panel="test-accordion-6">
          <div>
            Check to make sure you are running the latest version of your browser.
          </div>

          <div>
            Try rebooting your computer. If this does not solve the problem your internet may be too slow. You can try forcing the player to a lower resolution by clicking on the gear icon in the lower right corner of the video player and selecting “medium” or “low.”
          </div>
        </div>

        <h3 class="rvt-accordion__summary rvt-m-bottom-none">
          <button class="rvt-accordion__toggle" id="test-accordion-7-label" data-rvt-accordion-trigger="test-accordion-7">
            <span class="rvt-accordion__toggle-text">The audio and video are out of sync</span>
            <div class="rvt-accordion__toggle-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <g fill="currentColor">
                  <path class="rvt-accordion__icon-bar" d="M8,15a1,1,0,0,1-1-1V2A1,1,0,0,1,9,2V14A1,1,0,0,1,8,15Z" />
                  <path d="M14,9H2A1,1,0,0,1,2,7H14a1,1,0,0,1,0,2Z" />
                </g>
              </svg>
            </div>
          </button>
        </h3>
        <div class="rvt-accordion__panel" id="test-accordion-7" aria-labelledby="test-accordion-7-label" data-rvt-accordion-panel="test-accordion-7">
          Refresh or restart your browser. If this does not work, there may be a server-side synchronization problem. We do monitor the stream closely and will correct any problems as soon as possible, but if the issue persists after a few minutes, please reach out via email at <a href="mailto:iuml@iu.edu">iuml@iu.edu</a>.
        </div>

        <h3 class="rvt-accordion__summary rvt-m-bottom-none">
          <button class="rvt-accordion__toggle" id="test-accordion-8-label" data-rvt-accordion-trigger="test-accordion-8">
            <span class="rvt-accordion__toggle-text">The audio is too quiet </span>
            <div class="rvt-accordion__toggle-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <g fill="currentColor">
                  <path class="rvt-accordion__icon-bar" d="M8,15a1,1,0,0,1-1-1V2A1,1,0,0,1,9,2V14A1,1,0,0,1,8,15Z" />
                  <path d="M14,9H2A1,1,0,0,1,2,7H14a1,1,0,0,1,0,2Z" />
                </g>
              </svg>
            </div>
          </button>
        </h3>
        <div class="rvt-accordion__panel" id="test-accordion-8" aria-labelledby="test-accordion-8-label" data-rvt-accordion-panel="test-accordion-8">
          <div>
            The audio volume can be adjusted with the speaker icon in the lower right corner of the video player.
          </div>
          <div>
            We use very little audio dynamic range compression in our webcasts because we feel it is important to deliver an experience as close as possible to listening in the actual performance hall, which does result in quieter audio than most other content on the internet. We suggest using an external speaker system or headphones rather than built-in phone or computer speakers for the best quality and more audible volume during softer passages.
          </div>
        </div>

        <h3 class="rvt-accordion__summary rvt-m-bottom-none">
          <button class="rvt-accordion__toggle" id="test-accordion-9-label" data-rvt-accordion-trigger="test-accordion-9">
            <span class="rvt-accordion__toggle-text">Closed Captions</span>
            <div class="rvt-accordion__toggle-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <g fill="currentColor">
                  <path class="rvt-accordion__icon-bar" d="M8,15a1,1,0,0,1-1-1V2A1,1,0,0,1,9,2V14A1,1,0,0,1,8,15Z" />
                  <path d="M14,9H2A1,1,0,0,1,2,7H14a1,1,0,0,1,0,2Z" />
                </g>
              </svg>
            </div>
          </button>
        </h3>
        <div class="rvt-accordion__panel" id="test-accordion-9" aria-labelledby="test-accordion-9-label" data-rvt-accordion-panel="test-accordion-9">
          Live captioning is not currently available for all productions. Please contact us at <a href="mailto:iuml@iu.edu">iuml@iu.edu</a> if assistance is required accessing a captioned version of a stream.
        </div>

        <h3 class="rvt-accordion__summary rvt-m-bottom-none">
          <button class="rvt-accordion__toggle" id="test-accordion-10-label" data-rvt-accordion-trigger="test-accordion-10">
            <span class="rvt-accordion__toggle-text">I can’t find a specific performance in Video On Demand </span>
            <div class="rvt-accordion__toggle-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <g fill="currentColor">
                  <path class="rvt-accordion__icon-bar" d="M8,15a1,1,0,0,1-1-1V2A1,1,0,0,1,9,2V14A1,1,0,0,1,8,15Z" />
                  <path d="M14,9H2A1,1,0,0,1,2,7H14a1,1,0,0,1,0,2Z" />
                </g>
              </svg>
            </div>
          </button>
        </h3>
        <div class="rvt-accordion__panel" id="test-accordion-10" aria-labelledby="test-accordion-10-label" data-rvt-accordion-panel="test-accordion-10">
          <div>
            Due to copyright restrictions, only certain performances are available on demand. Additional videos can be found in the <a target="_blank" href="https://media.dlib.indiana.edu/catalog?f%5Bavalon_resource_type_ssim%5D%5B%5D=Moving+Image&f%5Bcollection_ssim%5D%5B%5D=Cook+Music+Library%2C+Jacobs+School+of+Music+Performances">MCO</a>. Some are publicly available, but the performance you are looking for may be unavailable or restricted to students and staff logged in with IU credentials.
          </div>
          <div>
            Not all performances are recorded with video. You can search the <a target="_blank" href="https://media.dlib.indiana.edu/catalog?f%5Bavalon_resource_type_ssim%5D%5B%5D=Sound+Recording&f%5Bcollection_ssim%5D%5B%5D=Cook+Music+Library%2C+Jacobs+School+of+Music+Performances">MCO</a> for audio recordings as well.
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
