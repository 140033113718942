<div class="row carousel-row">
  <div class="layout">
    <div class="title" [innerHtml]="title"></div>
    <ngx-slick-carousel class="carousel"
                        #slickModal="slick-carousel"
                        [config]="slideConfig"
                        (init)="slickInit($event)"
                        (breakpoint)="breakpoint($event)"
                        (afterChange)="afterChange($event)"
                        (beforeChange)="beforeChange($event)">
      <div ngxSlickItem *ngFor="let event of events; trackBy: _trackBy" class="slide rvt-m-lr-xs" (click)="thumbnailClicked(event)">
        <img src="{{ event.image }}" alt="" onerror="this.src='../../../assets/images/unavailable-image.png'" style="width:100%">
        <div class="event-bottom-text-wrapper">
          <div class="event-bottom-text-container">
            <span class="event-bottom-text-title" [innerHtml]="event.thumbnailTitle"></span>
            <br />
            <span class="event-bottom-text-subtitle" [innerHtml]=event.thumbnailCredit></span>
          </div>
        </div>
        <div *ngIf="event.isLive" class="live-indicator-block">
          <span class="live-indicator">
            <i class="fa fa-solid fa-circle blink" aria-hidden="true"></i>Live
          </span>
        </div>
      </div>
    </ngx-slick-carousel>
  </div>
</div>
