<div class="events-search">
  <h3 *ngIf="title" class="rvt-m-top-md rvt-m-bottom-none">{{title}}</h3>
  <div class="sticky top rvt-p-tb-xs">
    <div class="rvt-flex">
      <div class="rvt-grow-1 rvt-items-center">
        <input type="text" id="text-input-search" class="rvt-text-input rvt-m-bottom-none" placeholder="Search {{placeholderTitle}} Events" [(ngModel)]="searchText">
      </div>
      <div class="rvt-items-center">
        <div class="rvt-dropdown" data-rvt-dropdown="sortBy">
          <button type="button" class="rvt-button rvt-button--plain" data-rvt-dropdown-toggle="sortBy" aria-haspopup="true" aria-expanded="false">
            <span class="rvt-dropdown__toggle-text">Sort by {{sortText}}</span>
            <svg aria-hidden="true" class="rvt-m-left-xs" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <path fill="currentColor" d="M8,12.46a2,2,0,0,1-1.52-.7L1.24,5.65a1,1,0,1,1,1.52-1.3L8,10.46l5.24-6.11a1,1,0,0,1,1.52,1.3L9.52,11.76A2,2,0,0,1,8,12.46Z" />
            </svg>
          </button>
          <div class="rvt-dropdown__menu rvt-dropdown__menu--right" id="sortBy" hidden data-rvt-dropdown-menu>
            <button type="button" class="sort-dropdown-button" role="menuitemradio" (click)="toggleSort('date', 'desc')">
              Date (New - Old)
            </button>
            <button type="button" class="sort-dropdown-button" role="menuitemradio" (click)="toggleSort('date', 'asc')">
              Date (Old - New)
            </button>
            <button type="button" class="sort-dropdown-button" role="menuitemradio" (click)="toggleSort('title', 'asc')">
              Title (A - Z)
            </button>
            <button type="button" class="sort-dropdown-button" role="menuitemradio" (click)="toggleSort('title', 'desc')">
              Title (Z - A)
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="rvt-flex rvt-m-top-sm">
      <div *ngIf="filterMetadata.count == filterMetadata.count" class="rvt-grow-1">
        <b>{{filterMetadata.count}}</b> results found
      </div>
      <div id="help-tooltip" class="rvt-ts-xs help-tooltip rvt-items-start">
        <i>Can't find what you're looking for?</i>
      </div>
    </div>
  </div>

  <div class="rvt-row rvt-cols-md-12">
    <div *ngFor="let event of $any(events | filterEvents:searchText:filterMetadata | sortEvents:sortType:sortDirection | slice:0:numVisibleEvents)" class="rvt-cols-4-md rvt-m-tb-xs rvt-text-center" (click)="thumbnailClicked(event)">

      <!--Skeleton loader to show while event thumbnails load-->
      <ngx-skeleton-loader *ngIf="!event.isImageLoaded" id="{{event.id}}" [theme]="{ width: '100%', 'border-radius': '4px', 'padding-top':'55%' }"></ngx-skeleton-loader>

      <div [style.display]="event.isImageLoaded ? '' : 'none'" class="slide" style="border:1px solid black">
        <img src="{{ event.image }}" alt="" (load)="imageLoaded(event.id)" onerror="this.src='../../../assets/images/unavailable-image.png'" style="width:100%">
        <div class="event-bottom-text-wrapper">
          <div class="event-bottom-text-container">
            <span class="event-bottom-text-title" [innerHtml]="event.thumbnailTitle"></span>
            <br />
            <span class="event-bottom-text-subtitle" [innerHtml]="event.thumbnailCredit"></span>
          </div>
        </div>
        <div *ngIf="event.isLive" class="live-indicator-block">
          <span class="live-indicator">
            <i class="fa fa-solid fa-circle blink" aria-hidden="true"></i>Live
          </span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="filterMetadata.count > 0" class="rvt-text-center rvt-m-top-md">
    <div>
      Viewing 1 -
      <span *ngIf="numVisibleEvents <= filterMetadata.count">{{numVisibleEvents}}</span>
      <span *ngIf="numVisibleEvents > filterMetadata.count">{{filterMetadata.count}}</span>
      of {{filterMetadata.count}}
    </div>
    <button class="button button-override" [disabled]="numVisibleEvents >= filterMetadata.count" (click)="loadMoreEvents()">Load More</button>
  </div>
</div>
