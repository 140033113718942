import { Pipe, PipeTransform } from '@angular/core';
import { Event } from '../../core/models/Event';


@Pipe({ name: 'filterEvents' })
export class FilterEventsPipe implements PipeTransform {
  transform(events: Event[], searchText: string, filterMetadata): any {
    searchText = searchText.toLowerCase();

    var filteredEvents = events.filter(event =>
      event.thumbnailTitle.toLowerCase().indexOf(searchText) !== -1 ||
      event.thumbnailCredit.toLowerCase().indexOf(searchText) !== -1
    );

    filterMetadata.count = filteredEvents.length;
    return filteredEvents
  }
}
