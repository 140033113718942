<div class="rvt-container-lg rvt-m-top-sm">

  <div class="rvt-row">

    <!--@* START JW PLAYER *@-->
    <div class="rvt-cols-12-sm rvt-cols-12-md rvt-p-right-xxs-md-up">

      <!--JW Player skeleton loader-->
      <ngx-skeleton-loader *ngIf="!privateEvent" [theme]="{ 'border-radius': '4px', 'padding-top': '75%', 'margin-bottom': '-12px' }"></ngx-skeleton-loader>

      <!--JW Player Content-->
      <div *ngIf="privateEvent" class="jw-player-section">
        <jwplayer [selectedEvent]="privateEvent"></jwplayer>
      </div>
    </div>
    <!--@* END JW PLAYER *@-->

  </div>

  <div>
    &nbsp;
  </div>
</div>
