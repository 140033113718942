<div class="rvt-container-lg">
  <h2 class="rvt-p-tb-sm rvt-m-bottom-none">Video On Demand</h2>

  <div *ngIf="selectedEvent" class="jw-player-section rvt-m-bottom-sm">
    <jwplayer [selectedEvent]="selectedEvent"></jwplayer>
  </div>

  <!--hide for screens smaller than md (740px)-->
  <!--<div class="carousel-section rvt-hide-md-down">
    <div *ngFor="let group of groups">
      <carousel [title]="group.title" [events]="extractEvents(group)" (thumbnailClickedEvent)="setSelectedEvent($event)"></carousel>
      <br />
    </div>
  </div>-->
  <!--hide for screens greater than md (740px)-->
  <!--<div class="rvt-hide-md-up">
    <div class="sticky">
      <label for="select-input-season" class="rvt-label">Season:</label>
      <select id="select-input-season" class="rvt-select rvt-m-bottom-xs rvt-select-override" (change)="onSeasonChange($any($event.target).value)">
        <option *ngFor="let group of groups" [value]="group.title">{{group.title}}</option>
      </select>
    </div>
    <events-search *ngIf="selectedGroupEvents" [events]="selectedGroupEvents" (thumbnailClickedEvent)="setSelectedEvent($event)" class="top-128"></events-search>
  </div>-->

  <!--Grid view-->
  <div class="sticky">
    <label for="select-input-season" class="rvt-label">Season:</label>

    <!--Season dropdown skeleton loader-->
    <div *ngIf="!groups">
      <ngx-skeleton-loader [theme]="{ 'border-radius': '4px', height: '40px' }"></ngx-skeleton-loader>
    </div>

    <!--Season dropdown-->
    <div *ngIf="groups">
      <select id="select-input-season" class="rvt-select rvt-m-bottom-xs rvt-select-override" (change)="onSeasonChange($any($event.target).value)">
        <option *ngFor="let group of groups.slice(0, 4)" [value]="group.title">{{group.title}}</option>
      </select>
    </div>
  </div>

  <!--events-search skeleton loader-->
  <div *ngIf="!selectedGroupEvents">
    <ngx-skeleton-loader [theme]="{ 'border-radius': '4px', height: '39px' }"></ngx-skeleton-loader>
    <ngx-skeleton-loader [theme]="{ 'border-radius': '4px', height: '24px', width:'100px' }"></ngx-skeleton-loader>
    <ngx-skeleton-loader [theme]="{ 'border-radius': '4px', height: '24px', width:'100px', 'float': 'right' }"></ngx-skeleton-loader>

    <div class="rvt-row rvt-cols-md-12">
      <div *ngFor="let i of [].constructor(12)" class="rvt-cols-4-md">
        <ngx-skeleton-loader [theme]="{ width: '100%', 'border-radius': '4px', 'padding-top':'55%' }"></ngx-skeleton-loader>
      </div>
    </div>
  </div>

  <!--events-search-->
  <events-search *ngIf="selectedGroupEvents" [events]="selectedGroupEvents" [placeholderTitle]="selectedGroupTitle" (thumbnailClickedEvent)="setSelectedEvent($event)" class="top-128"></events-search>

  <div>&nbsp;</div>
</div>
