import { Component } from '@angular/core';
import { Subscription, switchMap } from 'rxjs';
import { EventsService } from '../../../core/services/events/events.service';
import { Event } from '../../../core/models/Event';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-embeddable-stream',
  templateUrl: './embeddable-stream.component.html',
  styleUrls: ['./embeddable-stream.component.css']
})
export class EmbeddableStreamComponent {
  private subscriptions: Subscription = new Subscription();
  public embeddableEvent: Event;
  public eventId: string;

  constructor(private eventsService: EventsService, private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.eventId = params['eventId'];
    });
  }

  ngOnInit() {
    this.subscriptions.add(
      this.eventsService.getEventBypassInterceptor(this.eventId).subscribe(embeddableEvent => {
        this.embeddableEvent = embeddableEvent;
      })
    )
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
